export const FR_TRANSLATIONS = {
  SERVICE_NAME: "Surveillance de l’énergie",
  BROWSER_TAB_TITLE: "myBKW: monitoring de l’énergie ",
  UNIT: {
    KW: "{{value}} kW",
    KWH: "{{value}} kWh",
  },
  SERVICE: {
    SUBSCRIPTION: {
      SUBMIT: {
        SUCCESS: "Les modifications ont bien été enregistrées.",
      },
      GENERAL: {
        ERROR: "Une erreur inattendue s’est produite. Veuillez réessayer ultérieurement. Si le service n’est toujours pas disponible, veuillez nous contacter à l’adresse <a href=\"mailto:smartmeter@bkw.ch\">smartmeter@bkw.ch</a>",
      },
    },
  },
  BFC_UNAUTHORIZED: {
    TITLE: "Accès refusé",
    CONTENT: "Vous n’avez pas le droit de consulter les données de ce smart meter.",
  },
  ENERGY_MONITORING: {
    MINUTES_15: "15 minutes",
    DAY: "Jour",
    MONTH: "Mois",
    YEAR: "Année",
  },
  GENERIC_REPORT: {
    START_DATE: "Date de début",
    START_TIME: "Heure de début",
    END_DATE: "Date de fin",
    END_TIME: "Heure de fin",
    EXPORT: "Exporter les données",
    HINT: {
      DAYLIGHT_SAVING: "Remarque : changement d’heure",
      DAYLIGHT_SAVING_ENTERED: "Ce jour-là, le passage de l’heure d’hiver à l’heure d’été a eu lieu entre 02:00-03:00 du matin. Par conséquent, l’heure entre 02:00-03:00 n’a pas eu lieu ce jour-là et la journée comptait 23 heures au total.",
      DAYLIGHT_SAVING_EXITED: "Ce jour-là, le passage de l’heure d’été à l’heure d’hiver a eu lieu entre 02:00-03:00 du matin. Par conséquent, l’heure entre 02:00-03:00 a eu lieu deux fois ce jour-là et la journée comptait 25 heures au total.",
    },
    COMPARE: {
      TITLE: "Période de comparaison",
      TIMERANGE_START: "De (date)",
      TIMERANGE_START_TIME: "De (heure)",
      TIMERANGE_END: "A (automatique)",
      REMOVE_BUTTON: "Supprimer",
    },
  },
  GRAPH_COMPONENT: {
    CLOCK: "heure",
    CHART: {
      LABEL: {
        CONSUMPTION: "Soutirage d’électricité",
        PRODUCTION: "Réinjection d’électricité",
        CONSUMPTION_COMPARISON: "Soutirage d’électricité période de comparaison",
        PRODUCTION_COMPARISON: "Réinjection d’électricité période de comparaison",
      },
      MENU: {
        VIEW_FULLSCREEN: "Plein écran",
        PRINT_CHART: "Imprimer le graphique",
        DOWNLOAD_PNG: "Télécharger le graphique au format PNG",
        DOWNLOAD_JPEG: "Télécharger le graphique au format JPEG",
        DOWNLOAD_PDF: "Télécharger le graphique au format PDF",
        DOWNLOAD_SVG: "Télécharger le graphique au format SVG",
      },
      INFO_BOX: {
        CONSUMPTION: "Votre soutirage d’électricité correspond à la quantité d’électricité (kWh) que vous prélèvez du réseau électrique pour le point de mesure choisi.",
        PRODUCTION: "Votre réinjection correspond à la quantité d’électricité (kWh) que vous produisez vous-même et (kWh) que vous injectez dans le réseau d’électricité pour le point de mesure choisi.",
      },
      RADIO_BUTTON: {
        ENERGY: "Données de consommation",
        POWER: "Puissance",
      },
    },
  },
  DATASHEET_COMPONENT: {
    TITLE: "Résumé",
    CONSUMPTION: "Soutirage d’électricité",
    PRODUCTION: "Réinjection d’électricité",
    COMPARISON_CONSUMPTION: "Comparaison soutirage d’électricité",
    COMPARISON_PRODUCTION: "Comparaison réinjection d’électricité",
    DESCRIPTION: "Les valeurs ci-dessous se réfèrent à l'intervalle de temps du graphique.",
    TOTAL_CONSUMPTION: "Soutirage totale d’électricité",
    TOTAL_PRODUCTION: "Réinjection totale d’électricité",
    PMAX: "Pmax",
  },
  OVERVIEW_LABELS: {
    BACK_TO_METER_SELECTION: "« retour à l’aperçu",
    BACK_TO_MY_BKW_ENERGY: "« retour à l’aperçu",
    TITLE: "Mon monitoring de l’énergie ",
    TEXT_UNDER_TITLE: "Ici vous pouvez consulter votre soutirage d’électricité et votre réinjection d’électricité au quart d’heure. Les données sont toujours actualisées pour la veille.",
    DISCLAIMER: "Clause de non-responsabilité : Le graphique indique les valeurs de soutirage d’électricité ou de réinjection d’électricité du client. Il sert uniquement à titre d’information et n’est pas contractuel. Seules les données de mesure plausibilisées utilisées dans le cadre de la facturation du soutirage d’électricité sont déterminantes.",
  },
  SELECTION: {
    TITLE: "Mon monitoring de l’énergie",
    TABLE: {
      HEADER: {
        METERING_CODE: "Point de mesure",
      },
    },
    BACK_TO_ADMIN_OVERVIEW: "« retour à l’aperçu Admin",
  },
  ADMIN: {
    TITLE: "Mon monitoring de l’énergie Admin",
    DESCRIPTION: "Commencez l’impersonnalisation avec le numéro de partenaire du client.",
    INPUT: {
      PLACEHOLDER_GP_NUMBER: "Numéro de client",
      HINT_GP_NUMBER: "Le numéro de client est composé de 8 caractères",
    },
    IMPERSONATION_LABEL: "Vue client, no du client GP: ",
    BUTTON: {
      LABEL: "Démarrer vue client ",
    },
  },
  CSV_EXPORT: {
    NOTIFICATION: {
      SUCCESS: "Le téléchargement a réussi",
    },
    DIALOG: {
      TITLE: "Télécharger le fichier CSV",
      DESCRIPTION: "Ici, vous pouvez télécharger les données sous forme de fichier CSV en valeurs de 15 minutes.",
      DATEPICKER: {
        START_DATE: "Date de début",
        END_DATE: "Date de fin",
      },
      BUTTON: {
        CANCEL: "Annuler",
        DOWNLOAD: "Télécharger",
      },
    },
    COLUMN: {
      DATE: "Date",
      CONSUMPTION: "Soutirage d’électricité [kWh]",
      PRODUCTION: "Réinjection [kWh]",
    },
    FIRST_ROW: {
      METERING_POINT: "Point de mesure:",
    },
  },
};